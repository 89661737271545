import _forEach from 'lodash/forEach';
import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import UAParser from 'ua-parser-js';

import EvTruck from '@magnus/react-native-evtruck';
import Mutator from '@magnus/react-native-mutator';
import DeviceProps from '@magnus/react-native-device-props';


//@ts-ignore
import Session from '@wowmaking/react-native-analytics/core/helpers/session';

import { trackEvent as trackEventSP } from '@web-solutions/sub-predict';

import * as AppyCNT from './appycnt';

declare global {
  interface Window {
    dataLayer?: Record<string, any>[];
  }
}

interface SubtruckExternalEventConfig {
  service: 'google_analytics' | 'facebook_conversion_api' | 'pinterest_conversion_api' | 'tik_tok_conversion_api' | 'snapchat_conversion_api';
  name: string;
  value: number;
  currency: string;
  additional_params?: {
    predicted_ltv?: number;
  }
}

let IDFM: string | undefined = undefined;
let MAGNUS_TOKEN: string | undefined = undefined;
let SUBTRUCK_EXTERNAL_EVENTS: SubtruckExternalEventConfig[] | undefined = undefined;

export const disabled =
  window.navigator?.userAgent?.includes?.('Google-Read-Aloud') ||
  window.navigator?.userAgent?.includes?.('adsbot-crawler'); // snapchat crawler

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.025,
});

const Analytics = {
  init(magnusToken: string, appVersion: string, options?: { skipSessionIncrement?: boolean }) {
    if (disabled) {
      return Promise.resolve();
    }

    MAGNUS_TOKEN = magnusToken;

    DeviceProps.getIDFM()
      .then(idfm => {
        IDFM = idfm;

        Sentry.setUser({ id: idfm });

        window.dataLayer?.push({
          idfm,
        });
      });

    DeviceProps.setAppVersion(appVersion);

    EvTruck.init({
      token: magnusToken,
    });

    const p = queryString.parse(window.location.search);
    const partition = window.location.pathname.split('/')[1];

    Mutator.setAttribution({ source: String(p.utm_source || ''), campaign_id: String(p.campaign_id || ''), });

    return (options?.skipSessionIncrement ? Promise.resolve() : Session.init(this)).then(() => {
      const mode = p.mode
        ? String(p.mode)
        : ['terminate', 'manage', 'asb'].includes(partition)
          ? undefined
          : '';

      this.setUserProperty('mode', mode);
      this.setUserProperty('trigger', p.trigger ? String(p.trigger) : undefined);

      this.setUserProperty('domain', window.location.hostname);
      this.setUserProperty('partition', partition);

      if (p.utm_source) {
        this.setUserProperty('utm_source', String(p.utm_source));
      }

      if (p.campaign_id) {
        this.setUserProperty('campaign_id', String(p.campaign_id));
      }

      if (p.adset_id) {
        this.setUserProperty('adset_id', String(p.adset_id));
      }

      if (p.creative_topic) {
        this.setUserProperty('ua_creative_topic', String(p.creative_topic));
      }

      this.trackEvent('session', 'url_params',
        _mapValues(
          _omit(p, ['utm_source', 'campaign_name', 'campaign_id', 'adset_name', 'adset_id', 'ad_name', 'ad_id', 'ad_subnetwork', 'ad_keyword', 'ad_placement']),
          String
        )
      );

      try {
        if (!window.localStorage.getItem('__install_time__')) {
          window.localStorage.setItem('__install_time__', String(Date.now()));
        }
      }
      catch (ex) { }

      if (p.reset !== undefined) {
        setInterval(() => {
          window.localStorage.clear();
        }, 10);
        setTimeout(() => {
          delete p.reset;
          window.location.href = queryString.stringifyUrl({ url: window.location.origin + window.location.pathname, query: p });
        }, 1000);
      }

      window.addEventListener('beforeunload', () => {
        this.trackEvent('session', 'end');
      });

      return p;
    });
  },

  trackMutatorSessionFirst() {
    let partition = window.location.pathname.split('/')[1];
    const p = queryString.parse(window.location.search);
    const r = new UAParser().getResult();
    // @ts-ignore
    window.ttq.page()
    this.trackEvent('mutator', 'session_first', { partition, mode: p.mode, utm_source: p.utm_source, browser: r?.browser?.name });
  },

  setUserProperty(name: string, value: string | boolean | null | undefined) {
    //@ts-ignore
    EvTruck.setUserProperty(name, value);
  },

  trackEvent(category: string, action: string, params?: Record<string, unknown>) {
    // console.log('trackEvent:', category, action, params);
    const event = `${category}_${action}`;
    EvTruck.trackEvent(event, params);
    trackEventSP(event, params);
    // window.dataLayer?.push({ event, ...params }); // GTM
    // @ts-ignore
    window.gtag && window.gtag('event', event, params);

    if (event === 'modal_payment_open') {
      SUBTRUCK_EXTERNAL_EVENTS = undefined;
      const p = queryString.parse(window.location.search);
      const installTime = +(window.localStorage.getItem('__install_time__') || Date.now());
      Mutator.getCountryCode()
        .then(countryCode =>
          fetch(queryString.stringifyUrl({
            url: 'https://subtruck.magnus.ms/api/v2/external-services-events/',
            query: {
              product_code: params?.productId as any,
              price: params?.value as any,
              currency: params?.currency as any,
              country: countryCode,
              seconds_after_install: Math.round((Date.now() - installTime) / 1000) + 16, // 16 - avg completion time modal_payment_open -> modal_payment_success
              source: p.utm_source,
              idfm: IDFM,
            }
          }), {
            method: 'GET',
            headers: {
              'Authorization': MAGNUS_TOKEN!,
            }
          })
        )
        .then(r => {
          if (r.ok) {
            return r.json()
          }
        })
        .then(data => {
          SUBTRUCK_EXTERNAL_EVENTS = data?.data;
        });
    }
  },

  trackPurchaseEvent(transaction: any) {
    EvTruck.trackEvent('ecommerce_purchase', {
      transactionId: transaction.transactionId,
      productId: transaction.productId,
      value: transaction.revenue,
      currency: transaction.currency,
      method: transaction.method,
      paymentSystem: transaction.paymentSystem,
      // externalEvents: SUBTRUCK_EXTERNAL_EVENTS?.length || 'null',
    });

    // window.dataLayer?.push({
    //   event: 'purchase',
    //   currency: transaction.currency,
    //   value: Number(transaction.revenue),
    //   transaction_id: transaction.transactionId,
    //   product_id: transaction.productId,
    //   method: transaction.method,
    // });

    AppyCNT.trackPurchaseEvent(transaction);

    _forEach(SUBTRUCK_EXTERNAL_EVENTS, e => {
      const value = e.value;
      const currency = e.currency;
      const eventID = e.name + '_' + transaction.transactionId;

      switch (e.service) {
        case 'google_analytics':
          //@ts-ignore
          window.gtag && window.gtag('event', e.name, {
            value,
            currency,
            transaction_id: transaction.transactionId,
          });
          break;
        case 'facebook_conversion_api':
          //@ts-ignore
          // window.fbq && window.fbq('track', e.name, {
          //   value,
          //   currency,
          //   predicted_ltv: e.additional_params?.predicted_ltv,
          // }, {
          //   eventID,
          // });
          break;
        case 'pinterest_conversion_api':
          //@ts-ignore
          window.pintrk && window.pintrk('track', e.name, {
            value,
            currency,
            event_id: eventID,
          });
          break;
        case 'tik_tok_conversion_api':
          const event_id = e.name + String(transaction.transactionId).replaceAll('-', '');
          // @ts-ignore
          window.ttq && window.ttq.track(e.name, {
            event_id: event_id,
            contents: [
              {
                content_id: transaction.productId,
                content_type: 'product',
                price: value,
                quantity: 1
              }],
            content_type: 'product',
            value,
            currency,
          });
          break;
        case 'snapchat_conversion_api':
          // @ts-ignore
          window.snaptr && window.snaptr('track', e.name, {
            price: value,
            currency,
            transaction_id: transaction.transactionId,
            client_dedup_id: eventID,
            predicted_ltv: e.additional_params?.predicted_ltv,
          });
          break;
      }
    });
  },

  getSessionNumber() {
    return Session.getNumber();
  },

  flush() {
    EvTruck.flush();
  },
};

export default Analytics;
