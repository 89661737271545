export interface RemoteConfigTakePhoto {
  photoButtonPriority: 'photo' | 'upload';
  isSkipPhotoStep: boolean;
  isResultScoreShowedBefore: boolean;
  takePhotoEnabled: boolean;
  takePhotoFlow: string[];
  uploadScreen: LocalizedUploadScreen,
  analysisScreen: LocalizedAnalysisScreen,
  resultScreen: LocalizedResultScreen,
  popupData: LocalizedPopupData,
}

export const INITIAL_CONFIG_TAKE_PHOTO: RemoteConfigTakePhoto = {
  photoButtonPriority: 'photo',
  isSkipPhotoStep: false,
  isResultScoreShowedBefore: false,
  takePhotoEnabled: true,
  takePhotoFlow: ['PHOTO_UPLOAD', 'PHOTO_ANALYSIS', 'PHOTO_RESULT'],
  uploadScreen: {
    en: {
      title: 'Your face reveals the secrets of your mind',
      subtitle: 'Facial features can uncover intelligence and cognitive traits',
      photoPrivacy: 'Your personal data is 100% secure. We don\'t share your personal data with third parties.',
      button: 'Take a photo',
      button_upload: 'Upload face photo'
    },
    es: {} as IUploadScreen,
    fr: {} as IUploadScreen,
    pt: {} as IUploadScreen,
    de: {} as IUploadScreen,
    tr: {} as IUploadScreen,
    it: {} as IUploadScreen,
    cs: {} as IUploadScreen,
    ro: {} as IUploadScreen,
    pl: {} as IUploadScreen,
    id: {} as IUploadScreen,
    ar: {} as IUploadScreen,
  },
  analysisScreen: {
    en: {
      analyzing: 'Analyzing your facial features…'
    },
    es: {} as IAnalysisScreen,
    fr: {} as IAnalysisScreen,
    pt: {} as IAnalysisScreen,
    de: {} as IAnalysisScreen,
    tr: {} as IAnalysisScreen,
    it: {} as IAnalysisScreen,
    cs: {} as IAnalysisScreen,
    ro: {} as IAnalysisScreen,
    pl: {} as IAnalysisScreen,
    id: {} as IAnalysisScreen,
    ar: {} as IAnalysisScreen,
  },
  resultScreen: {
    en: {
      title: 'Overview',
      progressBarData: [
        {percent: 81, fillColor: '#46B68F', title: 'Facial Symmetry'},
        {percent: 58, fillColor: '#FCAB6E', title: 'Eye Alignment'},
        {percent: 53, fillColor: '#ED6B6B', title: 'Head Tilt Angle'},
        {percent: 71, fillColor: '#FCAB6E', title: 'Brow Shape'},
      ],
      textData: [
        {
          text: 'Facial Symmetry: Symmetry reflects neural development and cognitive efficiency.',
          highlightedWords: [{word: 'Facial Symmetry', color: '#46B68F'}]
        },
        {
          text: 'Eye Alignment: Alignment indicates focus and visual processing skills.',
          highlightedWords: [{word: 'Eye Alignment', color: '#FCAB6E'}]
        },
      ],
      blurData: [
        {
          text: 'Head Tilt Angle: Head positioning can reveal attentiveness and problem-solving.',
          highlightedWords: [{word: 'Head Tilt Angle', color: '#ED6B6B'}]
        },
      ],
      moreText: 'More data in the full report',
      button: 'Get full IQ report'
    },
    es: {} as IResultScreen,
    fr: {} as IResultScreen,
    pt: {} as IResultScreen,
    de: {} as IResultScreen,
    tr: {} as IResultScreen,
    it: {} as IResultScreen,
    cs: {} as IResultScreen,
    ro: {} as IResultScreen,
    pl: {} as IResultScreen,
    id: {} as IResultScreen,
    ar: {} as IResultScreen,
  },
  popupData: {
    en: {
      title: 'Face not recognized. Please try again',
      button: 'Try again',
    },
    es: {} as IPopupData,
    fr: {} as IPopupData,
    pt: {} as IPopupData,
    de: {} as IPopupData,
    tr: {} as IPopupData,
    it: {} as IPopupData,
    cs: {} as IPopupData,
    ro: {} as IPopupData,
    pl: {} as IPopupData,
    id: {} as IPopupData,
    ar: {} as IPopupData,
  }
};

interface IUploadScreen {
  title: string,
  subtitle: string,
  photoPrivacy: string,
  button: string,
  button_upload: string,
}

export interface LocalizedUploadScreen {
  en: IUploadScreen;
  es: IUploadScreen;
  fr: IUploadScreen;
  pt: IUploadScreen;
  de: IUploadScreen;
  tr: IUploadScreen;
  it: IUploadScreen;
  cs: IUploadScreen;
  ro: IUploadScreen;
  pl: IUploadScreen;
  id: IUploadScreen;
  ar: IUploadScreen;
}

interface IAnalysisScreen {
  analyzing: string;
}

export interface LocalizedAnalysisScreen {
  en: IAnalysisScreen;
  es: IAnalysisScreen;
  fr: IAnalysisScreen;
  pt: IAnalysisScreen;
  de: IAnalysisScreen;
  tr: IAnalysisScreen;
  it: IAnalysisScreen;
  cs: IAnalysisScreen;
  ro: IAnalysisScreen;
  pl: IAnalysisScreen;
  id: IAnalysisScreen;
  ar: IAnalysisScreen;
}


interface IProgressData {
  percent: number,
  fillColor: string,
  title: string,
}

interface ITextData {
  text: string,
  highlightedWords: { word: string, color: string }[],
}
interface IResultScreen {
  title: string,
  progressBarData: IProgressData[],
  textData: ITextData[],
  blurData: ITextData[],
  moreText: string;
  button: string;
}

export interface LocalizedResultScreen {
  en: IResultScreen;
  es: IResultScreen;
  fr: IResultScreen;
  pt: IResultScreen;
  de: IResultScreen;
  tr: IResultScreen;
  it: IResultScreen;
  cs: IResultScreen;
  ro: IResultScreen;
  pl: IResultScreen;
  id: IResultScreen;
  ar: IResultScreen;
}

interface IPopupData {
  title: string,
  button: string,
}

export interface LocalizedPopupData {
  en: IPopupData;
  es: IPopupData;
  fr: IPopupData;
  pt: IPopupData;
  de: IPopupData;
  tr: IPopupData;
  it: IPopupData;
  cs: IPopupData;
  ro: IPopupData;
  pl: IPopupData;
  id: IPopupData;
  ar: IPopupData;
}