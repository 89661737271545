import _mapValues from 'lodash/mapValues';
import _merge from 'lodash/merge';
import queryString from 'query-string';

import DeviceProps from '@magnus/react-native-device-props';
import UserProps from '@magnus/react-native-user-props';
import EvTruck from '@magnus/react-native-evtruck';

import Web2App from '@wowmaking/web2app';

import { disabled } from '@web-solutions/module-analytics';

export type AppLinks = {
  ADJUST_LINK?: string;
  ADJUST_TOKEN?: string;
  APPSFLYER_LINK?: string;
  ADJUST_LINK_ANDROID?: string;
  ADJUST_TOKEN_ANDROID?: string;
  WEB_APP_LINK?: string;
}

type URLBaseParamsType = {
  urlExtraPath?: string;
  query?: Record<string, string>
}

let IDFM: string | undefined;
let appLinks: AppLinks | undefined;
let rcTarget: string | undefined;

let URLBaseParams: URLBaseParamsType = {
  urlExtraPath: '',
  query: {}
};


export enum LinkTarget {
  DEFAULT = 'default', // empty - by default - go to AF/Adjust link and then to store
  WEB = 'web', // target is web app
}


const AttributionModule = {
  init(links: AppLinks) {
    appLinks = links;

    DeviceProps.getIDFM().then((idfm) => {
      IDFM = idfm;
    });

    const params = queryString.parse(window.location.search);

    const attribution = _mapValues({
      utm_source: params.utm_source || '',
      campaign_name: params.campaign_name || '',
      campaign_id: params.campaign_id || '',
      adset_name: params.adset_name || '',
      adset_id: params.adset_id || '',
      ad_name: params.ad_name || '',
      ad_id: params.ad_id || '',
      http_referer: window.document.referrer || '',
      ad_subnetwork: params.ad_subnetwork || '',
      ad_keyword: params.ad_keyword || '',
      ad_placement: params.ad_placement || '',
    }, String);

    if (!disabled) {
      //@ts-ignore
      EvTruck.trackWebAttribution(attribution);

      Web2App.init({
        onChange: (system, p) => {
          // оборачиваем в массив, в будущем сможем поддерживать несколько пикселей на сайте и тп
          EvTruck.trackWebMeasurements({
            external_id: Web2App.getAttribution().web_external_id,
            [system]: [p],
          });
        },
      });

      if (appLinks.ADJUST_LINK && appLinks.ADJUST_TOKEN) {
        return Web2App.trackAdjustImpression(appLinks.ADJUST_TOKEN);
      }

      if (appLinks.ADJUST_LINK_ANDROID && appLinks.ADJUST_TOKEN_ANDROID) {
        return Web2App.trackAdjustImpression(appLinks.ADJUST_TOKEN_ANDROID);
      }
    }
  },

  setURLBaseParams(params: URLBaseParamsType) {
    URLBaseParams = _merge(URLBaseParams, params)
  },

  setRemoteConfigTarget(target: string) {
    rcTarget = target;
  },

  getMailLinkTarget() {
    return appLinks?.WEB_APP_LINK && rcTarget === LinkTarget.WEB ? LinkTarget.WEB : undefined;
  },

  async getLinkToApp(linkTargetExtra?: LinkTarget) {
    const source = 'sub';
    const params = queryString.parse(window.location.search);

    if (!appLinks) {
      return Promise.reject();
    }

    const up = await UserProps.fetch() as { utm_source: string, campaign_id: string, target: string, };
    const linkTarget = linkTargetExtra || up.target;
    const rcTargetExtra = linkTargetExtra || rcTarget;

    const w = appLinks.WEB_APP_LINK
      ? Promise.resolve(
        queryString.stringifyUrl({
          url: `${appLinks.WEB_APP_LINK}${URLBaseParams.urlExtraPath}`,
          query: {
            idfm: IDFM,
            source,
            mode: params.mode,
            utm_source: up.utm_source,
            campaign_id: up.campaign_id,
            ...URLBaseParams.query
          },
        })
      )
      : null;

    if ((linkTarget === LinkTarget.WEB || rcTargetExtra === LinkTarget.WEB) && w) {
      return w;
    } else if (appLinks.APPSFLYER_LINK) {
      return Web2App.createAppsFlyerLink(appLinks.APPSFLYER_LINK, { uuid: IDFM, is_retargeting: true, source });
    } else if (appLinks.ADJUST_LINK && appLinks.ADJUST_TOKEN) {
      const adj_redirect_android =
        appLinks.ADJUST_LINK_ANDROID && appLinks.ADJUST_TOKEN_ANDROID
          ? Web2App.createAdjustLink(appLinks.ADJUST_LINK_ANDROID, appLinks.ADJUST_TOKEN_ANDROID, {
            uuid: IDFM,
            source,
            mode: params.mode,
          })
          : undefined;

      return Web2App.createAdjustLink(appLinks.ADJUST_LINK, appLinks.ADJUST_TOKEN, {
        uuid: IDFM,
        source,
        mode: params.mode,
        adj_redirect_android,
      });

    } else {
      return Promise.reject(Error('Cannot create app link'));
    }
  },
};

export default AttributionModule;
