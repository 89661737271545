import { LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigSuccessStepsPage {
  successStepsPage: {
    main: {
      title: LocalizedString,
      subtitle: LocalizedString,
    }

    instruction: {
      title: LocalizedString,
      steps: LocalizedString[],
      buttonText: {
        android: LocalizedString,
        ios: LocalizedString
      }
    }

    ebooks: {
      title: LocalizedString,
      spam: LocalizedString,
      ebookText: LocalizedString,
      button: LocalizedString
      documents: {
        name: string,
        url: string
      }[]
    }

    button: LocalizedString
  }
}

export const INITIAL_CONFIG_SUCCESS_STEPS_PAGE: RemoteConfigSuccessStepsPage = {
  successStepsPage: {
    main: {
      title: {
        en: "Welcome to Brainary!",
        es: "",
        fr: "",
        pt: "",
        de: "",
        tr: "",
        it: "",
        cs: "",
        ro: "",
        pl: "",
        id: "",
        ar: "",
      },
      subtitle: {
        en: "Start your journey to smarter thinking today. Follow these simple steps to begin.",
        es: "",
        fr: "",
        pt: "",
        de: "",
        tr: "",
        it: "",
        cs: "",
        ro: "",
        pl: "",
        id: "",
        ar: "",
      },
    },

    instruction: {
      title: {
        en: 'To activate your account:',
        es: "",
        fr: "",
        pt: "",
        de: "",
        tr: "",
        it: "",
        cs: "",
        ro: "",
        pl: "",
        id: "",
        ar: "",
      },
      steps: [
        {
          en: "Download Brainary app by using <b>the button at the bottom.</b>",
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        },
        {
          en: "Once you have started the app, choose ‘Sign in with email’ (see the screenshot below).",
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        },
        {
          en: "Enter your email and follow the instructions.",
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        },
        {
          en: "After that is done, enjoy the premium features and start taking care of your loved ones!",
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        }
      ],
      buttonText: {
        android: {
          en: 'Go to Google Play',
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        },
        ios: {
          en: 'Go to App Store',
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        },
      }
    },

    ebooks: {
      title: {
        en: 'We’ve send your e-book to',
        es: "",
        fr: "",
        pt: "",
        de: "",
        tr: "",
        it: "",
        cs: "",
        ro: "",
        pl: "",
        id: "",
        ar: "",
      },
      spam: {
        en: '☝️ Sometimes it goes to Spam',
        es: "",
        fr: "",
        pt: "",
        de: "",
        tr: "",
        it: "",
        cs: "",
        ro: "",
        pl: "",
        id: "",
        ar: "",
      },
      ebookText: {
        en: 'e-book here',
        es: "",
        fr: "",
        pt: "",
        de: "",
        tr: "",
        it: "",
        cs: "",
        ro: "",
        pl: "",
        id: "",
        ar: "",
      },
      button: {
        en: 'Download',
        es: "",
        fr: "",
        pt: "",
        de: "",
        tr: "",
        it: "",
        cs: "",
        ro: "",
        pl: "",
        id: "",
        ar: "",
      },
      documents: [
        {
          name: "Brain Teasers",
          url: "https://d11bufd7orl7ul.cloudfront.net/brainary-ebook/Brain-Teasers-for-Brainary.pdf"
        },
        {
          name: "IQ Guide",
          url: "https://d11bufd7orl7ul.cloudfront.net/brainary-ebook/IQ-Guide-Brainary.pdf"
        }
      ]
    },

    button: {
      en: 'DOWNLOAD',
      es: "",
      fr: "",
      pt: "",
      de: "",
      tr: "",
      it: "",
      cs: "",
      ro: "",
      pl: "",
      id: "",
      ar: "",
    },
  }
}

