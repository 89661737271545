import { useAppSelector } from "@web-solutions/store/types"
import { LocalizedString } from "core/localization/constants/remote-config"
import React from 'react';
import { useSelector } from 'react-redux';
import { Textfit } from 'react-textfit'

import classNames from 'classnames';


import { LString, t, tm, TM } from "@web-solutions/module-localization"

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { PriceAfterTrialType } from 'core/payment/constants';
import { ProductDetails } from 'core/store/billing/selectors';
import TrialsLeft, { TrialsLeftType } from 'core/payment/components/trials-left';

import { DiscountWithTimer } from '../discount-with-timer';
import { SmallBadge } from '../small-badge';
import { SoldLine } from '../sold-line';

import classes from './style.module.scss';

const tKey = 'core.plans';

interface ItemProps {
  isActive: boolean,
  product: ProductDetails,
  onClick: (product: ProductDetails) => void,
  titleText: string,
  subTitleText?: LocalizedString|LString,
  perText: string,
  perTitle?: string,
};

export const Item: React.FC<ItemProps> = ({
  isActive,
  product,
  onClick,
  titleText,
  subTitleText,
  perText,
  perTitle,
}) => {
  const {
    weekPrice,
    priceText,
    period,
    isTrial,
    textLineThrough,
    accentTextLineThrough,
    sold,
    promocode,
    trialsLeftBadge,
    perWeekTitle,
    dayPriceAmount,
    perWeekText,
    title,
    subTitle
  } = product;

  const locale: keyof LocalizedString = useAppSelector(state => state.locale.locale);


  const { priceAfterTrialType } = useSelector(remoteConfigSelector);
  const promocodeActivated = useSelector((state: any) => state.billing.promocodeActivated)
  const afterTrialText = tm(
    product.afterTrialText,
    `${tKey}.after_trial.${period}`,
    {
      ...product,
      price:
        priceAfterTrialType === PriceAfterTrialType.WEEK
          ? `${weekPrice}/${t(`core.payment_popup.price_texts.P1W`)}`
          : priceText,
      interpolation: { escapeValue: false, },
    });

  const handleItemClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(product);
  };

  const isSoldOut = !!sold?.total && !sold.left;

  const isLightColorPerWeek = !!accentTextLineThrough && !isActive;

  // @ts-ignore
  return (
    <div className={classNames(classes.wrap, { [classes.disabled]: isSoldOut })}>
      {trialsLeftBadge && <TrialsLeft type={TrialsLeftType.BADGE_PRODUCT} />}
      <DiscountWithTimer
        discount={product.discount}
        // @ts-ignore
        badgeTitle={product.badgeTitle?.[locale]}
        centered={product.badgeCentered}
        disabled={isSoldOut}
      />
      <button
        type="button"
        onClick={handleItemClick}
        className={classNames(classes.container, { [classes.isActiveContainer]: isActive })}
        disabled={isSoldOut}
      >
        <div className={classes.detailWrap}>
          <p className={classes.productTitle}>
            {title?.[locale] ?? titleText}
            <SmallBadge
              texts={product.smallBadges}
              disabled={isSoldOut}
            />
          </p>
          {!sold?.hiddenSubTitles ?
          (
            <>
              <p className={classes.productSubTitle}>
                <TM k={subTitle} defaultKey='' tOptions={product} />
                {/*{subTitle}000*/}
                &nbsp;
                {textLineThrough && <span className={classes.lineThrough}>{tm(textLineThrough, '', product)}</span>}
              </p>
              {isTrial && (
                <p className={classes.afterTrialText}>
                  {afterTrialText}
                </p>
              )}
            </>
          )
          : null}
          {sold?.total ?
          (
            <SoldLine
              isActive={isActive}
              left={sold.left}
              total={sold.total}
            />
          )
          : null}
        </div>
        <div className={classes.accentWrap}>
          {promocodeActivated ?
            <div className={classes.promo_badge}>
              {t(`${tKey}.promo.discount`, { discount: promocode?.discount })}
            </div>
            :
            accentTextLineThrough
              ?
              <Textfit mode='single' min={15} max={17} className={classes.accentTextLineThrough}>
                {tm(accentTextLineThrough, '', product)}
              </Textfit>
              :
              null
          }
          <Textfit mode='single' min={17} max={20} className={classNames(classes.accentTitle, isLightColorPerWeek && classes.lightAccentTitle)}>
            {dayPriceAmount && perWeekTitle}
          </Textfit>
          <p className={classNames(classes.accentSubTitle, isLightColorPerWeek && !isActive && classes.lightAccentSubTitle)}>
            {perWeekText[locale]}
          </p>
        </div>
      </button>
    </div>
  );
};
