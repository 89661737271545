import axios from "axios";

import { ThunkAction } from "redux-thunk";

import { RootStatePackages } from "@web-solutions/store/types";

import Analytics from "@web-solutions/module-analytics";

import { setIsLoading } from "core/upsale/store/index";

interface ApiAuth {
  token_type: string;
  access_token: string;
}

const REACT_UP_SALE_API_BASE_URL = "https://mongo.myjoygames.com/api/v1/Users";

export const submitCancelSubscription = (
  recurlyId: string,
): ThunkAction<Promise<void>, RootStatePackages, unknown, any> => {
  return async (dispatch, getState) => {
    const apiAuth = getState().profile.apiAuth;
    const email = getState().profile.email;

    try {
      const profileResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/payments/subscriptions/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${apiAuth.token_type} ${apiAuth.access_token}`,
          },
        },
      );
      const subscriptions = profileResponse.data?.data;

      const subscriptionToCancel = subscriptions.find(
        (subscription: any) =>
          subscription.product !== recurlyId &&
          subscription.customer_email === email,
      );
      if (subscriptionToCancel) {
        await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/payments/subscriptions/cancel?&email=${email}&sig=${subscriptionToCancel.sig}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${apiAuth.token_type} ${apiAuth.access_token}`,
            },
          },
        );
        Analytics.trackEvent("subscription", "cancel");
      }
    } catch (error) {
      console.error("Error canceling the subscription:", error);
      throw error;
    }
  };
};

export const updateUserProfileData = (
  iq: number,
  purchasedUpsaleIds: string[],
  email?:string
): ThunkAction<Promise<void>, any, unknown, any> => {
  return async (dispatch, getState) => {
    const apiAuth: ApiAuth = getState().profile.apiAuth;
    dispatch(setIsLoading(true));
    try {
      const profileResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user/profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${apiAuth.token_type} ${apiAuth.access_token}`,
          },
        },
      );

      const userId = profileResponse.data?.data?.id;
      if (!userId) throw new Error("User ID not found");

      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}`,
        {
          profile_data: { iq },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${apiAuth.token_type} ${apiAuth.access_token}`,
          },
        },
      );

      const response = await axios.get(
        `${REACT_UP_SALE_API_BASE_URL}/authorize?id=${userId}`,
        {
          headers: {
            Accept: "application/json",
          },
        },
      );
      const token = response.data;

      if (token) {
        if (token) {
          await axios.post(
            `${REACT_UP_SALE_API_BASE_URL}/data`,
            {
              upsales: purchasedUpsaleIds,
              email
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`,
              },
            },
          );
        }
      }
    } catch (error) {
      console.error("Error updating IQ score:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
