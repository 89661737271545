import { LocalizedString } from "core/localization/constants/remote-config"

export enum UPSALE {
  MAIN = "/upsale",
  SKIP_TRIAL = "/upsale/screens/skip-trial",
  ADD_PACKS = "/upsale/screens/add-packs",
  EARLY_ACCESS = "/upsale/screens/early_access",
  DETAILED_REPORT = "/upsale/screens/detailed-report",
  OFFLINE_MODE = "/upsale/screens/offline-mode",
  DAYS_PLAN = "/upsale/screens/days-plan",
  ASSISTANT = "/upsale/screens/assistant",
  PREGNANCY_EBOOK = "/upsale/screens/pregnancy-ebook",
  MEDITATION_EBOOK = "/upsale/screens/meditation-ebook",
  MEGA_PACK = "/upsale/screens/mega-pack",
  BIG_UPSALE = "/upsale/screens/big-upsale",
}

export type ListItem = {
  icon: string;
  text: LocalizedString;
};

export type SuccessMessageType = {
  emoji: string;
  title: LocalizedString;
  message: LocalizedString;
};

export enum UPSALE_NAMES {
  SKIP_TRIAL = "skipTrial",
  ULTRA_PACK = "ultraPack",
  EARLY_ACCESS = "earlyAccess",
  DETAILED_REPORT = "detailedReport",
  OFFLINE_MODE = "offlineAccess",
  DAYS_PLAN = "daysPlan",
  ASSISTANT = "ASSISTANT",
  PREGNANCY_EBOOK = "pregnancyEbook",
  MEDITATION_EBOOK = "meditationEbook",
  MEGA_PACK = "megaPack",
  BIG_UPSALE = "bigUpsale",
  BIG_UPSALE_DISCOUNT = "bigUpsaleDiscount",
}
