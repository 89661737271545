import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { terminateSlice, manageSlice } from '@web-solutions/manage-subscription';

import remoteConfig from '@web-solutions/core/store/remote-config';
import billing from '@web-solutions/core/store/billing';
import { quizSlice } from '@web-solutions/core/store/quiz';
import { cookieSlice } from '@web-solutions/core/store/cookie';

import { appSlice, blacklist as appBlacklist } from '@web-solutions/base-app/store/app';
import { profileSlice, blacklist as profileBlacklist } from '@web-solutions/base-app/store/profile';
import { routingSlice, whitelist as routingWhitelist } from '@web-solutions/base-app/store/routing';

import upsaleReducer from '@web-solutions/core/upsale/store';
import videoReducer from '@web-solutions/core/video/store';

import takePhotoSlice from 'core/take-photo/store';

import localeReducer from '../localization/store';

const configureStore = () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const keyPrefix = `quiz-pp::`;

  const rootReducer = combineReducers({
    app: persistReducer(
      {
        key: `app`,
        storage,
        blacklist: appBlacklist,
      },
      appSlice.reducer
    ),
    remoteConfig: persistReducer(
      {
        key: `${keyPrefix}remoteConfig`,
        storage,
        blacklist: remoteConfig.blacklist,
        whitelist: remoteConfig.whitelist,
      },
      remoteConfig
    ),
    routing: persistReducer(
      {
        key: `${keyPrefix}routing`,
        storage,
        whitelist: routingWhitelist,
      },
      routingSlice.reducer
    ),
    billing: persistReducer(
      {
        key: `${keyPrefix}billing`,
        storage,
        blacklist: billing.blacklist,
        whitelist: billing.whitelist,
      },
      billing
    ),
    profile: persistReducer(
      {
        key: `${keyPrefix}profile`,
        storage,
        blacklist: profileBlacklist,
      },
      profileSlice.reducer
    ),
    upsale: persistReducer(
      {
        key: `${keyPrefix}upsale`,
        storage,
      },
      upsaleReducer.reducer
    ),
    quiz: persistReducer(
      {
        key: `${keyPrefix}quiz`,
        storage,
      },
      quizSlice.reducer
    ),
    takePhoto: persistReducer(
      {
        key: `${keyPrefix}takePhoto`,
        storage,
        blacklist: ['currentStep', 'initialPhoto', 'finalPhoto', 'isProcessingImage'],
      },
      takePhotoSlice.reducer
    ),

    [cookieSlice.name]: persistReducer(
      {
        key: `${keyPrefix}${cookieSlice.name}`,
        storage,
      },
      cookieSlice.reducer
    ),
    [terminateSlice.name]: terminateSlice.reducer,
    [manageSlice.name]: manageSlice.reducer,
    locale: localeReducer.reducer,
    video: persistReducer(
      {
        key: `${keyPrefix}video`,
        storage,
      },
      videoReducer.reducer
    ),
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return { store, persistor };
};

/**
 * @typedef {ReturnType<typeof rootReducer>} RootState
 */

export default configureStore;
