import { LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigWelcomeGender {
  quizWelcomeGenderPage: {
    title: LocalizedString,
    subtitle: LocalizedString,
    genders: {
      title: LocalizedString,
      variants: {
        female: LocalizedString,
        male: LocalizedString,
        binary: LocalizedString
      }
    }
  }
}

export const INITIAL_CONFIG_WELCOME_GENDER: RemoteConfigWelcomeGender = {
  quizWelcomeGenderPage: {
    title: {
      en: "Want to practice? Take Brainary <b>IQ Challenge</b>",
      es: "",
      fr: "",
      pt: "",
      de: "",
      tr: "",
      it: "",
      cs: "",
      ro: "",
      pl: "",
      id: "",
      ar: "",
    },
    subtitle: {
      en: "Your future improvement starts here - take the test and uncover your potential.",
      es: "",
      fr: "",
      pt: "",
      de: "",
      tr: "",
      it: "",
      cs: "",
      ro: "",
      pl: "",
      id: "",
      ar: "",
    },
    genders: {
      title: {
        en: 'Select your gender to start',
        es: "",
        fr: "",
        pt: "",
        de: "",
        tr: "",
        it: "",
        cs: "",
        ro: "",
        pl: "",
        id: "",
        ar: "",
      },
      variants: {
        female: {
          en: 'Female',
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        },
        male: {
          en: 'Male',
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        },
        binary: {
          en: 'Non-binary',
          es: "",
          fr: "",
          pt: "",
          de: "",
          tr: "",
          it: "",
          cs: "",
          ro: "",
          pl: "",
          id: "",
          ar: "",
        }
      }
    }
  }
}