import type { ProductAttachment } from "@web-solutions/core/interfaces/products";
import type { NoFundsOffer } from "@web-solutions/core/interfaces/remote-config";
import { type SpecialOfferMode } from "@web-solutions/core/interfaces/specialOffer";
import type { Theme } from "@web-solutions/core/interfaces/theme";
import {
  INITIAL_CONFIG as MANAGE_INITIAL_CONFIG,
  RemoteConfig as ManageRemoteConfig,
} from "@web-solutions/manage-subscription/src/manage/constants/remote-config";
import { LinkTarget } from "@web-solutions/module-attribution";
import { type LString } from "@web-solutions/module-localization";
import {
  Currency,
  ModeCardForm,
  PaymentSystem,
  Product as ProductDetails,
} from "@web-solutions/react-billing/constants";

import { ImageSource } from "core/interfaces/images";
import {
  INITIAL_CONFIG_LOCALES,
  LocalizedString,
  RemoteConfigLocales
} from "core/localization/constants/remote-config"

import {
  ButtonBehavior,
  ButtonConfig,
  ButtonPlacementType,
  CardFormLayout,
  PriceAfterTrialType,
} from "core/payment/constants";

import {
  INITIAL_CONFIG_UPSALES,
  RemoteConfigUpsales,
} from "core/upsale/constants/remote-config";

import { REDIRECT_TO_CHROME } from "core/utils/redirect-to-chrome";

import { INITIAL_CONFIG_TAKE_PHOTO, RemoteConfigTakePhoto } from "core/take-photo/constants/remote-config";
import {
  INITIAL_CONFIG_VIDEO_RECORDING,
  RemoteConfigVideoRecording
} from "core/video/constants/remote-config"

import {
  ErrorPopupType,
  InertialApplePay,
  ModeApplePay,
  ModeGooglePay,
  ModeMercadoPago,
  ModePayPal,
} from "./billing";
import { INITIAL_CONFIG_WELCOME_GENDER, RemoteConfigWelcomeGender } from "core/containers/welcome-gender/constants/remote-config";
import { INITIAL_CONFIG_WELCOME_IMAGE, RemoteConfigWelcomeImage } from "core/containers/welcome-image/constants/remote-config";
import { INITIAL_CONFIG_SUCESS_BUTTON_PAGE, RemoteConfigSuccessButtonPage } from "core/containers/success-button-page/constants/remote-config";
import { INITIAL_CONFIG_SUCCESS_STEPS_PAGE, RemoteConfigSuccessStepsPage } from "core/containers/success-steps-page/constants/remote-config";

export interface PolicyLinks {
  termsOfUse: string;
  privacy: string;
  moneyBack: string;
  billingTerms: string;
  address?: string;
  phone?: string;
  legalEntity?: string;
  descriptor?: string;
}

const POLICY_LINKS: PolicyLinks = {
  termsOfUse: "/terms-of-use",
  privacy: "/privacy-policy",
  moneyBack: "/money-back-policy",
  billingTerms: "/billing-terms",
};

const BTNS_CONFIGS: {
  [key in ButtonPlacementType]: ButtonConfig;
} = {
  [ButtonPlacementType.PAYMENT]: {
    title: "",
    uppercaseTitle: false,
  },
  [ButtonPlacementType.PLANS]: {
    behavior: ButtonBehavior.MODAL,
    title: "",
    uppercaseTitle: false,
    enabledFlareAnimation: true,
  },
  [ButtonPlacementType.FIXED]: {
    behavior: ButtonBehavior.SCROLL,
    title: "",
    uppercaseTitle: false,
    enabledFlareAnimation: true,
  },
};

export { LString };

export interface CurrencyConfig {
  notActivePrice: string;
  activePrice: string;
  price: number;
  discount: string;
}

export interface BaseOfferProductTypes<T> {
  title: LString;
  subTitle: string;
  notActivePrice: string;
  activePrice: string;
  discount: LString;
  price: number;
  currency: Currency;
  id: T;
  EUR?: CurrencyConfig;
  CAD?: CurrencyConfig;
  AUD?: CurrencyConfig;
  USD?: CurrencyConfig;
}

export type ProductConfig = {
  id?: string;
  paypalPlanId?: string;
  recurlyId?: string;
  solidgateId?: string;
  paddleId?: string;
  title?: LocalizedString;
  name?: LString;
  subTitle?: LocalizedString;
  descriptionText?: LString;
  badgeTitle?: LString;
  badgeCentered?: boolean;
  discount?: number;
  trialDiscount?: number;
  default?: boolean;
  textLineThrough?: LString;
  accentTitle?: LString;
  accentSubTitle?: LString;
  accentTextLineThrough?: LString;
  aboutTrialText?: LString;
  afterTrialText?: LString;
  showAfterTrialPrice?: boolean;
  sold?: { left: number; total: number; hiddenSubTitles?: boolean };
  promocode?: {
    discount: string;
    discountPrice: string;
  };
  smallBadges?: string[];
  trialsLeftBadge?: boolean;
  buttonTitle?: LString;
  attachment?: ProductAttachment;
  perWeekTitle?: string;
  priceStartTrial?: {
    price: LocalizedString;
    billingPeriod: LocalizedString;
    billedAmount: string;
    totalAmount: string;
    buttonLabel: string;
  };
  perWeekText: LocalizedString;
  paid_status: "free" | "promo" | "paid" | "trail";
};

type AdditionalFields = {
  email?: {
    label: string;
    type: string;
    isRequired: boolean;
  };
  cardholder?: boolean;
  zip?: boolean;
};

export enum InjectedPaymentMethod {
  APPLEPAY = "applepay",
  PAYPAL = "paypal",
  CARD = "card",
  GOOGLEPAY = "googlepay",
  APPLEPAY_PAYPAL = "applepay_paypal",
  GOOGLEPAY_PAYPAL = "googlepay_paypal",
  APPLEPAY_GOOGLEPAY = "applepay_googlepay",
}

export enum PaymentModalType {
  BASE = "base",
  BOTTOM_INJECTED = "bottom_injected",
  TOP_INJECTED = "top_injected",
  SIMPLE = "simple",
  SIMPLE_TITLE = "simple_title",
}

export enum PlanType {
  DEFAULT = "default",
  SINGLE = "single",
  HORIZONTAL = "horizontal",
  PDF = "pdf",
  TICKET = "ticket",
  MODAL = "modal",
}

interface Quiz {
  magic: {
    subTitle: LocalizedString;
  };
  answersTitle: LocalizedString;
  buttonTextResults: LocalizedString;
}

interface Email {
  title: LocalizedString;
  button: LocalizedString;
  placeholder: LocalizedString;
  notificationText: LocalizedString;
}

export type PriceTypes = "today" | "week" | "day";

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export interface RemoteConfig<FlowStep = string, ChatFlow = string>
  extends ManageRemoteConfig,
  RemoteConfigWelcomeGender,
  RemoteConfigWelcomeImage,
  RemoteConfigSuccessStepsPage,
  RemoteConfigSuccessButtonPage,
  RemoteConfigUpsales,
  RemoteConfigLocales,
  RemoteConfigTakePhoto,
  RemoteConfigVideoRecording {
  theme: Theme;
  successPageType: string;
  oneClickApplePayGooglePayMode: "1click" | "native" | "modal" | "native+modal";
  isOneClickFootnote: boolean;
  isDownloadPdfInBrowser: boolean;
  isCvvTooltip: boolean;
  displayVAT: boolean;
  configVAT: {
    withGeo: boolean;
    view: "simple" | "table";
  };
  linkTarget: LinkTarget;
  planType: PlanType;
  plansFeatures: string[];
  paymentDescriptionType: "old" | "new" | "icons";
  showTimerInPlanBadge: boolean;
  paymentModalEmailEnabled?: boolean;
  paymentProject: string;
  paymentSystem: PaymentSystem;
  paymentErrorRetries: {
    enabledFunds: boolean;
    enabled3ds: boolean;
    error3dsCodes: string[];
  };
  paymentModalHeader: {
    title?: LString;
    subtitle?: LString;
  };
  simpleTitleModal: {
    type: "default" | "short";
    titleEnabled: boolean;
    text: LString;
    fontSize?: number;
    withTotalPriceUnder?: boolean;
  };
  paymentSystemReserve: PaymentSystem | "";
  products: ProductConfig[];
  productsDetails: Array<
    Omit<ProductDetails, "period"> & { period: "ONETIME" }
  >;
  additionalFields: AdditionalFields;
  policyLinks: PolicyLinks;
  address: LocalizedString;
  buttonsConfigs: typeof BTNS_CONFIGS;
  priceAfterTrialType: PriceAfterTrialType;
  productsTitle: LocalizedString;
  showAfterTrialPrice: boolean;
  showPricesBlock: boolean;
  showPriceDiscount: boolean;
  openPaymentModalOnProductClick: boolean;
  isShowSafeCheckout: boolean;
  discountTime: number;
  modePayPal: ModePayPal;
  modeApplePay: ModeApplePay;
  inertialApplePay: InertialApplePay;
  modeGooglePay: ModeGooglePay;
  modeMercadoPago: ModeMercadoPago;
  cardFormCollapsed: boolean;
  cardFormLayout: CardFormLayout;
  showPaymentSafeAtPlans: boolean;
  showTermsAtPlans: boolean;
  totalPriceType: PriceTypes;
  isInjectedPaymentMethodOnPage: boolean;
  redirectToSuccessOnDecline: boolean;
  paymentModalType: PaymentModalType;
  trialInfoBlock: {
    show: boolean;
    periodMode: "day" | "week";
  };
  injectedPaymentMethods: InjectedPaymentMethod[];
  defaultInjectedPaymentMethod: InjectedPaymentMethod;
  quizPaymentModalFullMode: boolean;
  isPaymentModalShowAfterQuiz: boolean;
  errorPopup: {
    enabled: boolean;
    buttonTitle: string;
    type: ErrorPopupType;
    isDetailedErrors: boolean;
  };
  withOnlyEngFootnote: boolean;
  flow: Array<FlowStep | FlowStep[]>;
  consentCookieModal: {
    enabled: boolean;
    type: "long" | "short";
    placement: FlowStep;
  };
  isTrialPeriodReminder: boolean;
  trialsLeft: {
    badgeButtonEnabled: boolean;
    textEnabled: boolean;
    time: number;
  };
  promocodeOffer: {
    enabled: boolean;
    showPaymentModalDiscount: boolean;
    switchDefaultValue: boolean;
    products: ProductConfig[];
  };
  specialOffer: {
    mode: SpecialOfferMode;
    enabled: boolean;
    time: number;
    prevDiscount: number;
    discount: number;
    showAfterCloseAmount: number;
    products: ProductConfig[];
    scrollToTopAfterTrigger?: boolean;
    title?: LString;
    soInfoOfferText?: LString;
    guaranteedText: LString;
    guaranteedLink: LString;
    button: LocalizedString;
    guaranteedSaveText: LocalizedString;
  };
  noFundsOffer: NoFundsOffer;
  redirectToChrome: REDIRECT_TO_CHROME;
  isSendingTerminateLinks: boolean;
  isSendingTrialEnd: boolean;
  isTrialPriceSelection: boolean;
  chatFlow: {
    flow: Array<ChatFlow>;
    welcomeAnswerType: "single" | "multi" | "none";
    symbolsPerSecond: number;
    palmEnabled: boolean;
    redirectToSummary: boolean;
    nameStepEnabled: boolean;
    buttonText: LString;
  };
  modeCardForm: ModeCardForm;
  emailReportlLinkMode: "download" | "open" | "download+open";

  core: {
    termsInfo: {
      contact_us: LocalizedString;
      privacy_policy: LocalizedString;
      terms: LocalizedString;
      billing_terms: LocalizedString;
      money_back: LocalizedString;
    };
    quiz: Quiz;
    email: Email;
    summary: {
      plan: LocalizedString;
      image: ImageSource;
    };
    success: {
      title: {
        text: LocalizedString;
        highlightedText: Array<string>;
      };
      description: {
        text: LocalizedString;
        highlightedText: Array<string>;
      };
      footnote: LocalizedString;
      app: string;
      btn: LocalizedString;
      steps: {
        first: LocalizedString;
        second: LocalizedString;
        third: LocalizedString;
        fourth: LocalizedString;
      };
    };
  };
  paymentPopup: {
    total: {
      today: LocalizedString;
      day: LocalizedString;
      week: LocalizedString;
      total: LocalizedString;
      totalItem: LocalizedString;
    };
    selectedTitles: LocalizedString;
    trial: LocalizedString;
    editEmail: {
      title: LocalizedString;
      placeholder: LocalizedString;
      doubleCheck: LocalizedString;
      button: LocalizedString;
    };
    or: LocalizedString;
    payWithCard: LocalizedString;
    cardElementRecurly: {
      cardNumber: LocalizedString;
      mm: LocalizedString;
      yy: LocalizedString;
      firstName: LocalizedString;
      lastName: LocalizedString;
    };
    discountSave: LocalizedString;
  };
  videoBlock: {
    title: LocalizedString,
    description: LocalizedString,
    modalTitle: LocalizedString,
    modalDescription: LocalizedString,
    modalButton: LocalizedString
  },
  videoEnabled: boolean
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...MANAGE_INITIAL_CONFIG,
  ...INITIAL_CONFIG_UPSALES,
  ...INITIAL_CONFIG_LOCALES,
  ...INITIAL_CONFIG_TAKE_PHOTO,
  ...INITIAL_CONFIG_VIDEO_RECORDING,
  ...INITIAL_CONFIG_WELCOME_IMAGE,
  ...INITIAL_CONFIG_WELCOME_GENDER,
  ...INITIAL_CONFIG_SUCESS_BUTTON_PAGE,
  ...INITIAL_CONFIG_SUCCESS_STEPS_PAGE,
  successPageType: 'STEPS',
  withOnlyEngFootnote: false,
  paymentDescriptionType: "new",
  isCvvTooltip: false,
  isOneClickFootnote: true,
  isDownloadPdfInBrowser: false,
  displayVAT: false,
  planType: PlanType.DEFAULT,
  paymentErrorRetries: {
    enabledFunds: false,
    enabled3ds: false,
    error3dsCodes: [],
  },
  paymentModalHeader: {
    title: "",
    subtitle: "",
  },
  simpleTitleModal: {
    type: "default",
    titleEnabled: true,
    text: "<b>91% found</b> their happiness with highly-personalized predictions",
    fontSize: 22,
    withTotalPriceUnder: true,
  },
  plansFeatures: [],
  consentCookieModal: {
    enabled: false,
    type: "long",
    placement: "",
  },
  flow: [],
  linkTarget: LinkTarget.DEFAULT,
  showTimerInPlanBadge: false,
  paymentProject: "",
  paymentSystem: PaymentSystem.RECURLY,
  paymentSystemReserve: "",
  products: [],
  productsDetails: [],
  additionalFields: {},
  policyLinks: POLICY_LINKS,
  address: { en: "30 N. GOULD ST STE R SHERIDAN, WY 82801" } as LocalizedString,
  buttonsConfigs: BTNS_CONFIGS,
  priceAfterTrialType: PriceAfterTrialType.FULL,
  productsTitle: {} as LocalizedString,
  showAfterTrialPrice: true,
  showPricesBlock: true,
  showPriceDiscount: true,
  openPaymentModalOnProductClick: true,
  isShowSafeCheckout: true,
  discountTime: 120,
  modePayPal: ModePayPal.ENABLED,
  modeApplePay: ModeApplePay.ENABLED,
  inertialApplePay: InertialApplePay.DISABLED,
  modeGooglePay: ModeGooglePay.DISABLED,
  modeMercadoPago: ModeMercadoPago.DISABLED,
  cardFormCollapsed: false,
  cardFormLayout: CardFormLayout.COMPACT,
  showPaymentSafeAtPlans: false,
  showTermsAtPlans: false,
  totalPriceType: "today",
  paymentModalEmailEnabled: true,
  paymentModalType: PaymentModalType.BASE,
  trialInfoBlock: {
    show: false,
    periodMode: "day",
  },
  isInjectedPaymentMethodOnPage: false,
  redirectToSuccessOnDecline: false,
  defaultInjectedPaymentMethod: InjectedPaymentMethod.CARD,
  injectedPaymentMethods: [
    InjectedPaymentMethod.APPLEPAY,
    InjectedPaymentMethod.PAYPAL,
    InjectedPaymentMethod.CARD,
  ],
  quizPaymentModalFullMode: false,
  isPaymentModalShowAfterQuiz: false,
  errorPopup: {
    enabled: false,
    buttonTitle: "",
    type: ErrorPopupType.CARD,
    isDetailedErrors: false,
  },
  isTrialPeriodReminder: false,
  trialsLeft: {
    badgeButtonEnabled: false,
    textEnabled: false,
    time: 5000,
  },
  promocodeOffer: {
    enabled: false,
    switchDefaultValue: false,
    showPaymentModalDiscount: false,
    products: [],
  },
  specialOffer: {
    mode: "with_plans",
    enabled: false,
    time: 100,
    prevDiscount: 40,
    discount: 50,
    products: [],
    scrollToTopAfterTrigger: false,
    showAfterCloseAmount: 1,
    guaranteedLink: { en: "" },
    guaranteedText: { en: "" },
    button: {} as LocalizedString,
    guaranteedSaveText: {} as LocalizedString,
  },
  noFundsOffer: {
    enabled: false,
    time: 100,
    discount: 50,
    products: [],
    buttonTitle: "",
  },
  redirectToChrome: REDIRECT_TO_CHROME.DISABLED,
  isSendingTerminateLinks: false,
  isSendingTrialEnd: false,
  isTrialPriceSelection: false,
  chatFlow: {
    flow: [],
    welcomeAnswerType: "none",
    symbolsPerSecond: 8,
    palmEnabled: true,
    redirectToSummary: false,
    nameStepEnabled: true,
    buttonText: "",
  },
  theme: {},
  oneClickApplePayGooglePayMode: "1click",
  modeCardForm: ModeCardForm.ENABLED,
  emailReportlLinkMode: "download",
  configVAT: {
    withGeo: true,
    view: "simple",
  },

  core: {
    termsInfo: {
      contact_us: {} as LocalizedString,
      privacy_policy: {
        en: "",
      } as LocalizedString,
      terms: {
        en: "",
      } as LocalizedString,
      billing_terms: {
        en: "",
      } as LocalizedString,
      money_back: {
        en: "",
      } as LocalizedString,
    },
    quiz: {} as Quiz,
    email: {} as Email,
    summary: {
      plan: {
        en: "Get Your Personalized\nBrain Training Plan",
      } as LocalizedString,
      image: {} as ImageSource,
    },
    success: {
      title: {
        text: {} as LocalizedString,
        highlightedText: [],
      },
      description: {
        text: {} as LocalizedString,
        highlightedText: [],
      },
      footnote: {
        en: "The links will also be duplicated in the mail.",
      } as LocalizedString,
      app: "{{appName}}",
      btn: {
        en: "Download",
      } as LocalizedString,
      steps: {
        first: {
          en: "Download {{appName}} app by using <b>the button at the bottom of the screen.</b>",
        } as LocalizedString,
        second: {
          en: "Once you have started the app, choose '{{singIn}}' (see the screenshot below).",
        } as LocalizedString,
        third: {
          en: "Enter your email and follow the instructions.",
        } as LocalizedString,
        fourth: {
          en: "After that is done, enjoy the premium features and start taking care of your loved ones!",
        } as LocalizedString,
      },
    },
  },
  paymentPopup: {
    total: {
      today: {
        en: "Total today",
      } as LocalizedString,
      day: {
        en: "Total <span>per day</span>",
      } as LocalizedString,
      week: {
        en: "Total <span>per week</span>",
      } as LocalizedString,
      total: {
        en: "Total",
      } as LocalizedString,
      totalItem: {
        en: "Item price",
      } as LocalizedString,
    },
    selectedTitles: {
      en: "You selected {{titleText}}",
    } as LocalizedString,
    trial: {
      en: "{{days}}-Day Trial",
    } as LocalizedString,
    editEmail: {
      title: {
        en: "Email",
      } as LocalizedString,
      placeholder: {
        en: "Enter your email",
      } as LocalizedString,
      doubleCheck: {
        en: "Please double-check your email address before proceeding further",
      } as LocalizedString,
      button: { en: "ok" } as LocalizedString,
    },
    or: {
      en: "or",
    } as LocalizedString,
    payWithCard: {
      en: "Pay with card",
    } as LocalizedString,
    cardElementRecurly: {
      cardNumber: {
        en: "Card number",
      } as LocalizedString,
      mm: {
        en: "MM",
      } as LocalizedString,
      yy: {
        en: "YY",
      } as LocalizedString,
      firstName: {
        en: "First Name",
      } as LocalizedString,
      lastName: {
        en: "Last Name",
      } as LocalizedString,
    },
    discountSave: {} as LocalizedString,
  },
  videoBlock: {
    title: { en: 'Facial analysis' } as LocalizedString,
    description: { en: `We're analyzing your facial expressions as you take the test.` } as LocalizedString,
    modalTitle: { en: 'We need camera access' } as LocalizedString,
    modalDescription: { en: 'Allow camera access to analyze your expressions for accurate IQ results' } as LocalizedString,
    modalButton: { en: 'Continue' } as LocalizedString
  },
  videoEnabled: false
};
