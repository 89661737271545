import { LocalizedString } from "core/localization/constants/remote-config"
import React, { useState, useMemo, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import _map from 'lodash/map';
import classNames from 'classnames';

import { useAppSelector } from "@web-solutions/store/types"

import { TM, t, tm } from '@web-solutions/module-localization';
import { useRemoteConfig } from '@web-solutions/base-app/hooks/use-remote-config';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { selectPaymentSystem } from 'core/store/billing/selectors';
import { InjectedPaymentMethod, PaymentModalType } from 'core/constants/remote-config';
import { CardFormLayout } from 'core/payment/constants';
import { ProductInfo } from 'core/payment/base/product-info';
import PaymentForm from 'core/payment/components/payment-form';
import TrialPrice from 'core/payment/base/product-info/components/trial-price';
import ApplePay from 'core/payment/components/apple-pay';
import { PayPalBtn } from 'core/payment/components/paypal';
import { MercadoPagoBtn } from 'core/payment/components/mercado-pago';
import TrialReminder from 'core/payment/components/trial-reminder';
import { Title } from 'core/ui-elements';

import { Options } from 'core/payment';
import { type Subscription } from 'core/interfaces/billing';

import InjectedPaymentMethodHeader from 'core/summary/injected-payment-method/components/header';
import InjectedPaymentMethodSwitch from 'core/summary/injected-payment-method/components/switch';

import { PaymentSafe } from 'core/containers/payment-safe';

import { ProductDetails, selectInjectedPaymentMethod } from 'core/store/billing/selectors';
import type { PaymentModalTitle } from 'core/interfaces/payment-modal';

import PaymentDescription from '../../components/payment-description';
import { TotalPrice } from '../product-info/components/simple-view';
import { getActualPrices } from '../product-info/components/simple-view/utilts';

import { ICONS } from './icons/cards';
import { ReactComponent as IconArrowDown } from './icons/icon-arrow-down.svg';
import { ReactComponent as IconCreditCard } from './icons/credit-card.svg';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

interface Props {
  activeProduct: ProductDetails,
  onSubmit: (formData: any) => Promise<void>,
  onSuccess: (purchase: Subscription) => void,
  onError: (error: any) => void,
  options: Options,
  showApplePay: boolean,
  showPayPal: boolean,
  showGooglePay: boolean,
  onApplePayClick: () => void,
  onApplePaySubmit: (formData: any) => Promise<void>,
  onGooglePayClick: () => void,
  onGooglePaySubmit: (formData: any) => Promise<void>,
  onPayPalSubmit: (formData: any) => Promise<void>,
  onPayPalSuccess: (p: Subscription) => void,
  onPayPalError: (e: any) => void,
  onUserInfoChange: (info: any) => void,
  isOnlyBaseModal?: boolean,
  title?: PaymentModalTitle,
  subtitle?: React.ReactNode,
  showCard?: boolean,
  showMercadoPago: boolean,
  upsaleTrigger?:string,
  onSaveTokenOneTime?:(tokenId:string)=>void
}

const BasePaymentModalBody: React.FC<Props> = ({
  activeProduct,
  onSubmit,
  onSuccess,
  onError,
  isOnlyBaseModal,
  options,
  showApplePay,
  showPayPal,
  showGooglePay,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onPayPalSubmit,
  onPayPalSuccess,
  onPayPalError,
  onUserInfoChange,
  title,
  subtitle,
  showCard = true,
  showMercadoPago,
  upsaleTrigger,
                                                 onSaveTokenOneTime
}) => {
  const paymentSystem = useSelector(selectPaymentSystem);
  const {
    isShowSafeCheckout,
    showTermsAtPlans,
    cardFormCollapsed,
    cardFormLayout,
    isInjectedPaymentMethodOnPage,
    paymentModalType,
    isTrialPeriodReminder,
    simpleTitleModal,
    totalPriceType
  } = useSelector(remoteConfigSelector);
  const hiddenAltMethods = isInjectedPaymentMethodOnPage && !activeProduct?.isOneTimePurchase;
  const [showCardForm, setShowCardForm] = useState(!cardFormCollapsed || (!showApplePay && !showGooglePay && !showPayPal));

  const injectedPaymentMethod = useSelector(selectInjectedPaymentMethod);

  const injectedApplePay = injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_GOOGLEPAY || injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY || injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_PAYPAL;
  const injectedPayPal = injectedPaymentMethod === InjectedPaymentMethod.PAYPAL || injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_PAYPAL || injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY_PAYPAL;
  const injectedGooglePay = injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_GOOGLEPAY || injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY || injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY_PAYPAL;
  const injectedCard = injectedPaymentMethod === InjectedPaymentMethod.CARD;
  const injectedApplePayOrGooglePay = injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_GOOGLEPAY;
  const injectedGooglePayPayPal = injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY_PAYPAL;
  const injectedApplePayPayPal = injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_PAYPAL;

  const modalType = isOnlyBaseModal ? PaymentModalType.BASE : paymentModalType;

  const isBaseModal = modalType === PaymentModalType.BASE;
  const isTopModal = modalType === PaymentModalType.TOP_INJECTED;
  const isSimpleModal = modalType === PaymentModalType.SIMPLE;
  const isSimpleTitleModal = modalType === PaymentModalType.SIMPLE_TITLE;
  const isBottomModal = modalType === PaymentModalType.BOTTOM_INJECTED;

  const isSimpleView = isSimpleModal || isSimpleTitleModal;

  const locale: keyof LocalizedString = useAppSelector(state => state.locale.locale);
  const handlePayWithCardClick = (e: MouseEvent) => {
    e.preventDefault();
    if (showApplePay || showPayPal || showGooglePay) {
      setShowCardForm(!showCardForm);
    }
  };

  const formWrapStyle = classNames(classes.formWrap, {
    [classes.formWrapOpen]: showCardForm || injectedCard,
  });

  const expandWrapStyle = classNames(classes.expandWrap, {
    [classes.expand]: showCardForm || injectedCard,
  });

  const color = useMemo(() => {
    const root = document.querySelector(':root') as HTMLElement;

    return getComputedStyle(root).getPropertyValue('--injectedPaymentPrimaryColor');
  }, [])

  const cardFormProps = useMemo(() => ({
    isSimpleView,
    product: activeProduct,
    color,
    options,
    paymentSystem,
    formClassName: 'payment-form',
    fieldClassName: classNames('form-field', { 'form-field-compact': cardFormLayout === CardFormLayout.COMPACT }),
    onSubmit,
    onSuccess,
    onError,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
    onUserInfoChange,
    onSaveTokenOneTime
  }), [
    isSimpleView,
    color,
    activeProduct,
    options,
    cardFormLayout,
    paymentSystem,
    onSubmit,
    onSuccess,
    onError,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
    onUserInfoChange,
    onSaveTokenOneTime
  ]);

  const { fullPrice, actualPrice, vat, vatPrice } = getActualPrices({ activeProduct, totalPriceType })
  const { paymentPopup } = useRemoteConfig()

  return (
    <div className={classNames(classes.contentContainer, { [classes.simple]: isSimpleView })}>
      {isSimpleTitleModal && simpleTitleModal?.titleEnabled && (
        <header className={classes.header} style={!simpleTitleModal?.withTotalPriceUnder ? { display: 'contents' } : {}}>
          <Title
            className={classes.title}
            style={(simpleTitleModal?.fontSize ? { fontSize: `${simpleTitleModal.fontSize}px` } : {})}
          >
            {title?.simple_title || <TM k={t(`${tKey}.simple_type.title_default`)} />}
          </Title>
          {simpleTitleModal?.withTotalPriceUnder &&
            <TotalPrice
              className={classes.totalPrice}
              mode={simpleTitleModal?.type}
              vat={vat}
              vatPrice={vatPrice}
              fullPrice={fullPrice}
              actualPrice={actualPrice}
            />
          }
        </header>
      )}
      <div className={classNames(classes.wrap, { [classes.simple_title]: isSimpleTitleModal })}>
        {
          (isBaseModal || isBottomModal) && (
            <>
              <ProductInfo
                product={activeProduct}
                subtitle={subtitle}
              />
              {
                (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) ?
                  <TrialReminder className={classes.reminder} /> :
                  <div className={classes.divider} />
              }
            </>
          )
        }
        {
          (isTopModal || isBottomModal) && (
            <>
              <InjectedPaymentMethodHeader isCentralHeader={isTopModal} />
              <InjectedPaymentMethodSwitch isBaseSwitch />
            </>
          )
        }

        {
          isSimpleView && (
            <>
              <InjectedPaymentMethodSwitch isBaseSwitch />
              <ProductInfo
                product={activeProduct}
                subtitle={subtitle}
                isSimpleView={isSimpleView}
              />
              <div className={classes.divider} />
            </>
          )
        }

        <div className={classNames(classes.payment, isBottomModal ? classes.row : classes.column)}>
          {
            isBottomModal && !injectedCard && (
              <TrialPrice
                textLineThrough={tm(activeProduct.textLineThrough, '', activeProduct)}
                trialPrice={activeProduct.trialPrice}
                className={classes.price}
              />
            )
          }

          {
            isTopModal && (
              <>
                <div className={classes.space} />
                <ProductInfo product={activeProduct} />
                {
                  (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) && <TrialReminder className={classes.reminder} />
                }
              </>
            )
          }

          <div className={classNames(classes.paymentMethods, { [classes.hidden]: hiddenAltMethods })}>
            {
              ((showApplePay && injectedApplePay) || (showApplePay && isBaseModal)) && (
                <div style={{ marginTop: '16px' }} className={classes.buttonWrapper}>
                  <ApplePay
                    activeProduct={activeProduct}
                    className={classes.appleButton}
                    onApplePayClick={onApplePayClick}
                    onApplePaySubmit={onApplePaySubmit}
                  />
                </div>
              )
            }
            {
              <div className={classNames(classes.buttonWrapper, { [classes.hidden]: (!showApplePay || !(injectedApplePay || isBaseModal)) })}>
                <div id={options?.applePayButtonParams?.containerId}></div>
              </div>
            }

            {showApplePay && ((isBaseModal && showCard) || (injectedApplePayPayPal && showPayPal) || (injectedApplePayOrGooglePay && showGooglePay)) && (
              <div className={classes.or}>
                <p>{paymentPopup.or[locale]}</p>
              </div>
            )}

            {
              <div className={classNames(classes.buttonWrapper, { [classes.hidden]: !(injectedGooglePay || isBaseModal) })}>
                <div id={options?.googlePayButtonParams?.containerId}></div>
              </div>
            }

            {
              (!injectedApplePayOrGooglePay && ((showGooglePay && ((isBaseModal && showCard) || injectedGooglePayPayPal)))) && (
                <div className={classes.or}>
                  <p>{paymentPopup.or[locale]}</p>
                </div>
              )
            }

            {(showPayPal && (injectedPayPal || isBaseModal)) && (
              <>
                <PayPalBtn
                  product={activeProduct}
                  className={classes.paypal}
                  onSubmit={onPayPalSubmit}
                  onSuccess={onPayPalSuccess}
                  onError={onPayPalError}
                />
                {
                  (isBaseModal && showCard) && (
                    <div className={classes.or}>
                      <p>{paymentPopup.or[locale]}</p>
                    </div>
                  )
                }
              </>
            )}
            {showMercadoPago && (
              <>
                <MercadoPagoBtn
                  product={activeProduct}
                  upsaleTrigger={upsaleTrigger}
                />
                {
                  (isBaseModal && showCard) && (
                    <div className={classes.or}>
                      <p>{paymentPopup.or[locale]}</p>
                    </div>
                  )
                }
              </>
            )}
          </div>
        </div>
        <div className={classNames(formWrapStyle, {
          [classes.hidden]: !showCard || !(injectedCard || isBaseModal),
          [classes.simple_view]: isSimpleView,
        })}>
          <button
            type="button"
            onClick={handlePayWithCardClick}
            className={classes.payWithCard}
          >
            <IconCreditCard width={30} height={21} className={classes.icon_card} />
            <p className={classes.payWithCardText}>{paymentPopup.payWithCard[locale]}</p>
            {(showApplePay || showPayPal || showGooglePay) && !injectedCard && (
              <div className={classes.arrowDownWrap}>
                <IconArrowDown
                  width={12}
                  height={6}
                  className={classNames(classes.iconArrow, {
                    [classes.iconArrowUp]: showCardForm,
                  })}
                />
              </div>
            )}
          </button>
          <div className={expandWrapStyle}>
            <ul className={classes.cardsList}>
              {_map(ICONS, (Icon, key) => (
                <li key={key} className={classes.cardItem}>
                  <Icon width="100%" height="100%" />
                </li>
              ))}
            </ul>
            <PaymentForm {...cardFormProps} />
          </div>
        </div>

        {(!activeProduct.isOneTimePurchase) && (
          <>
            {isShowSafeCheckout ? <PaymentSafe /> : null}
            {!showTermsAtPlans && (
              <PaymentDescription
                activeProduct={activeProduct}
              />
            )}
          </>
        )}
      </div>
    </div >
  );
};


export default BasePaymentModalBody;
