import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TTakePhotoStatuses = "initial" | "loading" | "loaded"

export const takePhotoStatuses: Record<TTakePhotoStatuses, TTakePhotoStatuses> = {
  initial: 'initial',
  loading: 'loading',
  loaded: 'loaded',
};

export interface TakePhotoState {
  currentStep: number;
  initialPhoto: string;
  finalPhoto: string;
  takePhotoStatus: keyof typeof takePhotoStatuses;
  isErrored: boolean;
  isProcessingImage: boolean;
  isShowBurgerMenuForEmail: boolean;
}

const initialState: TakePhotoState = {
  currentStep: 0,
  initialPhoto: '',
  finalPhoto: '',
  takePhotoStatus: 'initial',
  isErrored: false,
  isProcessingImage: true,
  isShowBurgerMenuForEmail: false
};

const takePhotoSlice = createSlice({
  name: "take_photo",
  initialState,
  reducers: {
    nextStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },
    setInitialPhoto: (state, action: PayloadAction<string>) => {
      state.initialPhoto = action.payload
    },
    setFinalPhoto: (state, action: PayloadAction<string>) => {
      state.finalPhoto = action.payload
    },
    setIsErrored: (state, action: PayloadAction<boolean>) => {
      state.isErrored = action.payload
    },
    setTakePhotoStatus: (state, action: PayloadAction<keyof typeof takePhotoStatuses>) => {
      state.takePhotoStatus = action.payload
    },
    setIsProcessingImage: (state, action: PayloadAction<boolean>) => {
      state.isProcessingImage = action.payload
    },
    setIsShowBurgerMenuForEmail: (state, action: PayloadAction<boolean>) => {
      state.isShowBurgerMenuForEmail = action.payload
    },
  },
});

export const {
  nextStep,
  setInitialPhoto,
  setFinalPhoto,
  setIsErrored,
  setTakePhotoStatus,
  setIsProcessingImage,
  setIsShowBurgerMenuForEmail
} = takePhotoSlice.actions;

export default takePhotoSlice;
