export const BASE_QUIZ = {
  EMAIL: 'EMAIL',
  MAGIC: 'MAGIC',
  VIDEO_RESULT:'VIDEO_RESULT',
  TAKE_PHOTO: 'TAKE_PHOTO',
} as const;

type Keys = keyof typeof BASE_QUIZ;
export type BaseQuizRoute = typeof BASE_QUIZ[Keys];

export const SUBSCRIPTION = {
  PRESUMMARY: '/subscription/presummary',
  MAIN: '/subscription/main',
  SUCCESS: '/subscription/success',
} as const;



