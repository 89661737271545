import { AxiosResponse } from "axios";

import { toast } from "react-toastify";

import instance from "./instance";
import {
  ProcessPaymentRequest,
  ProcessPaymentResponse,
  Purchase,
} from "./types";

export const processPayment = async (purchase: Purchase, recurlyId: string) => {
  if (!purchase.token_id) {
    throw new Error("Purchase token is missing");
  }

  const requestBody: ProcessPaymentRequest = {
    token: purchase.token_id!,
    email: purchase.email,
    itemCode: recurlyId,
    currency: "USD",
    projectType: process.env.REACT_APP_PAYMENT_PROJECT!,
    three_d_secure_action_result_token_id:
      purchase.three_d_secure_action_result_token_id,
  };

  try {
    const response: AxiosResponse<ProcessPaymentResponse> = await instance.post(
      process.env.REACT_APP_ONE_TIME_PAYMENT_ENDPOINT || "",
      requestBody,
    );

    const { data } = response;

    if (!data.data.chargeInvoice) {
      throw new Error("Payment processing error: Charge invoice is missing");
    }

    return data.data.chargeInvoice;
  } catch (error: any) {
    toast.error(error.response.data.error.transactionError.message);
  }
};
