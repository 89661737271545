import { ButtonConfig } from "@web-solutions/core/payment/constants";

import { LString, t } from "@web-solutions/module-localization";
import { useAppSelector } from "@web-solutions/store/types";

export const useButtonTitleByConfig = (
  config: ButtonConfig,
  defaultTKeyText: string,
  details?: object,
) => {
  let { uppercaseTitle, title } = config || {};
  const locale = useAppSelector((state) => state.locale.locale);

  if (title && typeof title === "string" && title[0] === "_") {
    return t(title);
  }

  if (title) {
    const upgradeTitle = title[locale as keyof LString] as string;

    return uppercaseTitle ? upgradeTitle.toUpperCase() : upgradeTitle;
  }
};
