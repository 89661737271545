export interface RemoteConfigVideoRecording {
  videoResultScreen: IResultScreen,
}

export const INITIAL_CONFIG_VIDEO_RECORDING: RemoteConfigVideoRecording = {
  videoResultScreen: {
      title: {en:'Overview'},
      progressBarData: [
        {percent: 81, fillColor: '#46B68F', title: {en:'Facial Symmetry'}},
        {percent: 58, fillColor: '#FCAB6E', title: {en:'Eye Alignment'}},
        {percent: 53, fillColor: '#ED6B6B', title: {en:'Head Tilt Angle'}},
        {percent: 71, fillColor: '#FCAB6E', title: {en:'Brow Shape'}},
      ],
      textData: [
        {
          text: {en:'Facial Symmetry: Symmetry reflects neural development and cognitive' +
              ' efficiency.'},
          highlightedWords: [{word: {en:'Facial Symmetry'}, color: '#46B68F'}]
        },
        {
          text: {en:'Eye Alignment: Alignment indicates focus and visual processing skills.'},
          highlightedWords: [{word: {en:'Eye Alignment'}, color: '#FCAB6E'}]
        },
      ],
      blurData: [
        {
          text: {en:'Head Tilt Angle: Head positioning can reveal attentiveness and' +
              ' problem-solving.'},
          highlightedWords: [{word: {en:'Head Tilt Angle'}, color: '#ED6B6B'}]
        },
      ],
      moreText: {en:'More data in the full report'},
      button: {en:'Get full IQ report'}
    },
};


interface IProgressData {
  percent: number,
  fillColor: string,
  title: any,
}

interface ITextData {
  text: any,
  highlightedWords: { word: any, color: string }[],
}
interface IResultScreen {
  title: any,
  progressBarData: IProgressData[],
  textData: ITextData[],
  blurData: ITextData[],
  moreText: any;
  button: any;
}

