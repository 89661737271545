import { LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigWelcomeImage {
  quizWelcomeImagePage: {
    title: LocalizedString,
    subtitle: LocalizedString,
    buttonText: LocalizedString
  }
}

export const INITIAL_CONFIG_WELCOME_IMAGE: RemoteConfigWelcomeImage = {
  quizWelcomeImagePage: {
    title: {
      en: "Measure Your <b>IQ Score</b> Today",
      es: "",
      fr: "",
      pt: "",
      de: "",
      tr: "",
      it: "",
      cs: "",
      ro: "",
      pl: "",
      id: "",
      ar: "",
    },
    subtitle: {
      en: "Find out your IQ in minutes with our accurate, research-backed test!",
      es: "",
      fr: "",
      pt: "",
      de: "",
      tr: "",
      it: "",
      cs: "",
      ro: "",
      pl: "",
      id: "",
      ar: "",
    },
    buttonText: {
      en: "Start Certified Test",
      es: "",
      fr: "",
      pt: "",
      de: "",
      tr: "",
      it: "",
      cs: "",
      ro: "",
      pl: "",
      id: "",
      ar: "",
    }
  }
}