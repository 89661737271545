import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VideoState {
  cameraPermission: boolean | null;
  recordedVideo: string | null;
  isVideoResultScreenTracked:boolean
}

const initialState: VideoState = {
  cameraPermission: null,
  recordedVideo: null,
  isVideoResultScreenTracked: false,
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setCameraPermission: (state, action: PayloadAction<boolean>) => {
      state.cameraPermission = action.payload;
    },
    setRecordedVideo: (state, action: PayloadAction<string>) => {
      state.recordedVideo = action.payload;
    },
    clearRecordedVideo: (state) => {
      state.recordedVideo = null;
    },
    setVideoResultScreenTracked(state) {
      state.isVideoResultScreenTracked = true;
    },
  },
});

export const {
  setCameraPermission,
  setRecordedVideo,
  clearRecordedVideo,
  setVideoResultScreenTracked
} = videoSlice.actions;

export default videoSlice;
