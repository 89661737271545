import { LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigSuccessButtonPage {
  successButtonPage: {
    title: LocalizedString,
    subtitle: LocalizedString,
  }
}

export const INITIAL_CONFIG_SUCESS_BUTTON_PAGE: RemoteConfigSuccessButtonPage = {
  successButtonPage: {
    title: {
      en: "Welcome to Brainary!",
      es: "",
      fr: "",
      pt: "",
      de: "",
      tr: "",
      it: "",
      cs: "",
      ro: "",
      pl: "",
      id: "",
      ar: "",
    },
    subtitle: {
      en: "Discover your full potential with engaging lessons, activities, and games",
      es: "",
      fr: "",
      pt: "",
      de: "",
      tr: "",
      it: "",
      cs: "",
      ro: "",
      pl: "",
      id: "",
      ar: "",
    },
  }
}